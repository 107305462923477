import React, { PureComponent } from "react";
import styled from "styled-components";
import posed from "react-pose";
import { Text } from "rebass";
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDirections } from "@fortawesome/free-solid-svg-icons/faDirections";

// layout
import { Container, Row, Column, PosedSection } from "components/layout";
import Map from "components/Map";
import PinchZoom from "components/PinchZoom";

import { green } from "utils/mapStyles";
import { sectionHeading } from "components/typography/sizes";
import { PoseableHeading, PoseableText } from "components/poseable";
import { fadeUp } from "components/poses";
import { captureOutboundLink } from "utils/trackEvent";
import markerIcon from "./marker.svg";

const DirectionsLink = styled(posed(PoseableText)(fadeUp))`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: color 0.3s ease-in;
  :hover,
  :focus {
    outline: none;
    color: ${({ theme }) => theme.revealColor};
    transition: color 0.3s ease-out;
  }
`;

const PosedHeading = posed(PoseableHeading)(fadeUp);

const directionsUrl = `https://www.google.com/maps/dir/Current+Location/47+Jefferson+Ave,+Toronto,+ON+M6K+1Y3,+Canada`;

export default class ContactMap extends PureComponent {
  state = { loaded: false, entered: false };

  render() {
    const { latitude, longitude, theme } = this.props;

    const { entered } = this.state;
    return (
      <PosedSection
        visibleCondition={true}
        visible={entered}
        /*visibleCondition={this.state.loaded}*/ onEnter={() => {
          this.setState({ entered: true });
        }}
      >
        <Container>
          <Row multiline={false}>
            <Column width={1}>
              <PosedHeading
                is="h3"
                color={theme.fgColor}
                fontFamily="Calibre"
                fontWeight="bold"
                fontSize={sectionHeading}
                lineHeight={1.66666666667}
              >
                Map
              </PosedHeading>
            </Column>
          </Row>
          <Row multiline={false}>
            <Column width={1}>
              <PinchZoom revealColor={theme.revealColor} visibledelay={200}>
                <Map
                  markerIcon={markerIcon}
                  mapStyle={green}
                  latitude={latitude}
                  longitude={longitude}
                  zoom={15}
                  placeholderColor={theme.revealColor}
                  onLoad={() => this.setState({ loaded: true })}
                />
                <DirectionsLink
                  visibledelay={2000}
                  color={theme.textColor}
                  is={`a`}
                  href={directionsUrl}
                  onClick={() => captureOutboundLink(directionsUrl)}
                  title={`Get Directions`}
                  target={`_blank`}
                  px={[15, 20, 30]}
                  py={[30, 30, 40]}
                  fontFamily="Calibre"
                  fontWeight="bold"
                  fontSize={[16, 18]}
                >
                  <Text is="i" fontSize={[20, 24]} mr={[10, 12]}>
                    <FontAwesomeIcon icon={faDirections} />
                  </Text>
                  <span>Get Directions</span>
                </DirectionsLink>
              </PinchZoom>
            </Column>
          </Row>
        </Container>
      </PosedSection>
    );
  }
}
