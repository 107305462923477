import { Column, Container, PosedSection, Row } from "components/layout";

import BaseTemplate from "templates/base";
import BigPageLink from "components/BigPageLink";
import ContactMap from "components/contact/ContactMap";
import Content from "components/typography/Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "components/Footer";
import { HelmetDatoCms } from "gatsby-source-datocms";
import PageHeading from "components/PageHeading";
import { PoseableColumn } from "components/poseable";
import React from "react";
import SectionAside from "components/typography/SectionAside";
import { Text } from "rebass";
import TextButtonWithArrow from "components/buttons/TextButtonWithArrow";
import { captureOutboundLink } from "utils/trackEvent";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { fadeUp } from "components/poses";
import { graphql } from "gatsby";
import posed from "react-pose";
import { smallHeading } from "components/typography/sizes";
import styled from "styled-components";
import { withTheme } from "styled-components";

// const isProduction = process.env.NODE_ENV === `production`;

// console.log("CONTACT:::", {
//   isProduction,
//   NODE_ENV: process.env.NODE_ENV,
//   analytics: process.env.GOOGLE_ANALYTICS_ID,
// });

const renderBlock = (block, theme) => {
  const apiKey = block && block.model ? block.model.apiKey : null;
  let Cmp = null;
  let extras = {};
  switch (apiKey) {
    case "big_page_link":
      Cmp = BigPageLink;
      extras = { big: true };
      break;
    default:
      break;
  }
  if (Cmp) {
    return <Cmp theme={theme} key={block.id} {...block} {...extras} />;
  }

  return null;
};

const SocialLink = styled(Text)`
  will-change: color;
  transition: color 0.2s ease-in;
  display: inline-block;
  margin-right: 1em;
  margin-top: 5px;
  :hover,
  :focus {
    outline: none;
    transition: color 0.2s ease-out;
    color: ${(props) => props.theme.fgColor};
  }
`;

const PosedColumn = posed(PoseableColumn)(fadeUp);

class ContactTemplate extends BaseTemplate {
  render() {
    const { ready } = this.state;
    const { data, theme } = this.props;
    const { page, jobPostings, events, internshipsPage, globals } = data;
    const { heading, seoMetaTags, body } = page;

    const allJobPostings = jobPostings.edges.map((jp) => jp.node);
    const allEvents = events.edges.map((e) => e.node);

    const {
      addressLine1,
      addressLine2,
      postalCode,
      phoneNumber,
      infoEmail,
      linkedinUrl,
      instagramUrl,
      twitterUrl,
      newBusinessName,
      newBusinessEmail,
      location,
    } = globals;

    const {
      footerSmallHeading,
      footerHeading,
      footerLinkLabel,
      footerLink,
    } = page;

    return (
      <React.Fragment>
        <HelmetDatoCms seo={seoMetaTags}>
          <link rel="preload" as="image" href="/loader-white.gif" />
        </HelmetDatoCms>
        <PageHeading
          theme={theme}
          heading={heading}
          ready={ready}
          headingProps={{ pt: `2vh` }}
        />
        {/* Contact Info */}
        <PosedSection pb={[40, 60, 80, 100, 120]} autoShow={true}>
          <Container>
            <Row>
              <SectionAside num="Contact" color={theme.fgColor} />
              <Column width={[1, 1, 1, 5 / 12, 1 / 3]}>
                <Content
                  posed={true}
                  visibledelay={200}
                  color={theme.textColor}
                  fontFamily={"Larish"}
                  fontSize={smallHeading}
                  lineHeight={1.433333}
                >
                  {addressLine1}
                  <br />
                  {addressLine2} {postalCode}
                </Content>
              </Column>
              <PosedColumn width={[1, 1, 1, 5 / 12, 1 / 3]} visibledelay={300}>
                <Content
                  is={`a`}
                  color={theme.textColor}
                  fontFamily={"Larish"}
                  fontSize={smallHeading}
                  href={`tel:${phoneNumber}`}
                  onClick={() => captureOutboundLink(`tel:${phoneNumber}`)}
                  style={{ display: `inline-block`, textDecoration: `none` }}
                >
                  {phoneNumber}
                </Content>
                <br />
                <Content
                  is={`a`}
                  color={theme.textColor}
                  fontFamily="Larish"
                  fontSize={smallHeading}
                  lineHeight={1.433333}
                  href={`mailto:${infoEmail}`}
                  onClick={() => captureOutboundLink(`mailto:${infoEmail}`)}
                  title="Contact us"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: `inline-block`, textDecoration: `none` }}
                  mt={0}
                >
                  {infoEmail}
                </Content>
              </PosedColumn>
              <PosedColumn
                width={[1, 1, 1, 1 / 2, 1 / 4]}
                ml={[0, 0, 0, `${(1 / 12) * 100}%`, 0]}
                mt={[0, 0, `-0.3em`]}
                visibledelay={400}
              >
                <SocialLink
                  is={`a`}
                  fontSize={[20, 22, 24]}
                  title={"Linkedin"}
                  href={linkedinUrl}
                  onClick={() => captureOutboundLink(linkedinUrl)}
                  target="_blank"
                  rel="noopener noreferrer"
                  color={theme.textColor}
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </SocialLink>
                <SocialLink
                  is={`a`}
                  onClick={() => captureOutboundLink(instagramUrl)}
                  fontSize={[20, 22, 24]}
                  title={"Instagram"}
                  href={instagramUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  color={theme.textColor}
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </SocialLink>
                <SocialLink
                  is={`a`}
                  onClick={() => captureOutboundLink(twitterUrl)}
                  fontSize={[20, 22, 24]}
                  title={"Twitter"}
                  href={twitterUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  color={theme.textColor}
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </SocialLink>
              </PosedColumn>
            </Row>
          </Container>
        </PosedSection>
        {/* New Business Info */}
        <PosedSection pb={[40, 60, 80, 100, 120]}>
          <Container>
            <Row flexWrap="wrap">
              <SectionAside label="New Business" color={theme.fgColor} />
              <PosedColumn visibledelay={200} width={[1, 1, 1, 5 / 6]}>
                <Content
                  is="span"
                  color={theme.textColor}
                  fontFamily={"Larish"}
                  fontSize={smallHeading}
                  lineHeight={1.433333}
                  style={{ display: `inline-block`, textDecoration: `none` }}
                >
                  {newBusinessName}
                </Content>
                <br />
                <Content
                  is={`a`}
                  color={theme.textColor}
                  fontFamily="Larish"
                  fontSize={smallHeading}
                  lineHeight={1.433333}
                  href={`mailto:${newBusinessEmail}`}
                  onClick={() =>
                    captureOutboundLink(`mailto:${newBusinessEmail}`)
                  }
                  title={`Contact ${newBusinessName}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: `inline-block`, textDecoration: `none` }}
                  mt={0}
                >
                  {newBusinessEmail}
                </Content>
              </PosedColumn>
            </Row>
          </Container>
        </PosedSection>
        {/* Jobs */}
        {allJobPostings && allJobPostings.length > 0 && (
          <PosedSection pb={[40, 60, 80, 100, 120]} id="careers">
            <Container>
              <Row flexWrap="wrap">
                <SectionAside label="Careers" color={theme.fgColor} />
                <PosedColumn
                  width={[1, 1, 1, 5 / 6]}
                  style={{ display: `flex`, flexDirection: `column` }}
                >
                  {allJobPostings.map((jp, jpIdx) => (
                    <TextButtonWithArrow
                      visibledelay={150 * jpIdx}
                      color={theme.textColor}
                      fontFamily={theme.fonts.body}
                      label={jp.title}
                      url={`/jobs/${jp.url}`}
                      mt={jpIdx === 0 ? 0 : [2, 3, 4]}
                      // style={{ marginTop: jpIdx === 0 ? 0 : 5 }}
                    />
                  ))}
                </PosedColumn>
              </Row>
            </Container>
          </PosedSection>
        )}
        {/* Jobs */}
        {allEvents && allEvents.length > 0 && (
          <PosedSection pb={[40, 60, 80, 100, 120]} id="events">
            <Container>
              <Row flexWrap="wrap">
                <SectionAside label="Events" color={theme.fgColor} />
                <PosedColumn
                  width={[1, 1, 1, 5 / 6]}
                  style={{ display: `flex`, flexDirection: `column` }}
                >
                  {allEvents.map((e, eIdx) => (
                    <TextButtonWithArrow
                      visibledelay={150 * eIdx}
                      color={theme.textColor}
                      fontFamily={theme.fonts.body}
                      label={e.title}
                      url={`/events/${e.url}`}
                      mt={eIdx === 0 ? 0 : [2, 3, 4]}
                    />
                  ))}
                </PosedColumn>
              </Row>
            </Container>
          </PosedSection>
        )}
        {/* Training camp */}
        <PosedSection>
          <Container>
            <Row flexWrap="wrap">
              <SectionAside
                label="Internship<br/>Program"
                color={theme.fgColor}
              />
              <PosedColumn visibledelay={300} width={[1, 1, 1, 5 / 6]}>
                <TextButtonWithArrow
                  color={theme.textColor}
                  fontFamily={theme.fonts.body}
                  label={`Learn more about Training Camp`}
                  url={`/${internshipsPage.url}`}
                />
              </PosedColumn>
            </Row>
          </Container>
        </PosedSection>
        <ContactMap {...location} theme={theme} ready={ready} />
        {/* Body */}
        {body && body.map((block) => renderBlock(block, theme))}
        <Footer
          theme={theme}
          footerSmallHeading={footerSmallHeading}
          footerHeading={footerHeading}
          footerLinkLabel={footerLinkLabel}
          footerLink={footerLink}
          globals={this.props.pageContext.globals}
        />
        {/* {isProduction && (
          <script
            async
            defer
            id="ContactFormLeadLanderScript"
            key="ContactFormLeadLanderScript"
            dangerouslySetInnerHTML={{
              __html: `var formalyze = [];formalyze.auto = true;formalyze.callback = function(options) {};(function() {var a = document.createElement('script');a.src = 'https://lltrck.com/scripts/formalyze.js?llid=29653';var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(a, s);})();`,
            }}
          />
        )} */}
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query {
    globals: datoCmsGlobal {
      addressLine1
      addressLine2
      postalCode
      phoneNumber
      infoEmail
      linkedinUrl
      instagramUrl
      twitterUrl
      newBusinessName
      newBusinessEmail
      location {
        latitude
        longitude
      }
    }
    internshipsPage: datoCmsInternshipsPage {
      title
      url
    }
    jobPostings: allDatoCmsJobPosting(filter: { active: { ne: false } }) {
      edges {
        node {
          id
          url
          active
          title
          model {
            apiKey
          }
        }
      }
    }
    events: allDatoCmsEventPage {
      edges {
        node {
          id
          url
          title
          model {
            apiKey
          }
        }
      }
    }
    page: datoCmsContactPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heading
      body {
        ... on DatoCmsBigPageLink {
          id
          model {
            apiKey
          }
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
          linkLabel
          link
        }
      }
      footerSmallHeading
      footerHeading
      footerLinkLabel
      footerLink
    }
  }
`;

export default withTheme(ContactTemplate);
