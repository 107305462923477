import React, { PureComponent } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

import constants from "utils/constants";
import { InView } from "react-intersection-observer";

const MapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${constants.GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div
        style={{
          top: 0,
          left: 0,
          width: `100%`,
          height: `100%`,
          position: `absolute`,
        }}
      />
    ),
    mapElement: (
      <figure
        style={{
          position: `absolute`,
          top: 0,
          left: 0,
          width: `100%`,
          height: `100%`,
        }}
      />
    ),
  }),
  withScriptjs,
  withGoogleMap
)(
  ({
    latitude,
    longitude,
    markerIcon = null,
    mapStyle = null,
    isMarkerShown = true,
    zoom = 8,
    onMarkerClick = null,
    onLoad,
    ...rest
  }) => {
    return (
      <GoogleMap
        defaultOptions={{
          styles: mapStyle,
          disableDefaultUI: true,
        }}
        defaultZoom={zoom || 8}
        defaultCenter={{ lat: latitude, lng: longitude }}
        center={{ lat: latitude, lng: longitude }}
        onTilesLoaded={onLoad}
        {...rest}
      >
        {isMarkerShown && (
          <Marker
            icon={markerIcon}
            position={{ lat: latitude, lng: longitude }}
            onClick={onMarkerClick}
          />
        )}
      </GoogleMap>
    );
  }
);

export default class Map extends PureComponent {
  state = { inView: false };
  render() {
    const { placeholderColor, onLoad, ...rest } = this.props;
    return (
      <InView
        as={`figure`}
        style={{
          position: `relative`,
          display: `block`,
          width: `100%`,
          paddingBottom: `56.25%`, //16:9
          backgroundColor: placeholderColor || `transparent`,
        }}
        triggerOnce={true}
        onChange={(inView) => {
          if (inView) this.setState({ inView: true });
        }}
      >
        {this.state.inView && (
          <MapComponent onLoad={onLoad} {...rest} isMarkerShown={true} />
        )}
      </InView>
    );
  }
}
